import React, { useState, useContext, useEffect } from "react"
import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";
// import QrReader from 'react-qr-scanner'
import QrReader from 'modern-react-qr-reader'
import BorderBox from "../components/BorderBox.tsx"
import { useNavigate } from "react-router-dom";
import { FeeCollectionContext } from "../lib/context/FeeCollectionContext.tsx"
import { getPropertyDetails } from "../lib/apis/index.ts"
import { ToastContainer, toast } from "react-toastify";
import audio from "../assets/audio/audio2.mp3"
import { Howl, Howler } from 'howler';
import { useSearchParams, useParams } from "react-router-dom";
// import logo from "../assets/images/logo.jpg"

const ScanPropertyCode = (props: any) => {
    const [searchParams] = useSearchParams();
    const ddn = searchParams.get("ddn")
    // console.log(searchParams.get("ddn")); 
    // const {ddn} = useParams();

    // console.log(ddn);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    let navigate = useNavigate();

    const { userInfo, updateUserInfo, isLoading, handleLoader }: any = useContext(FeeCollectionContext)

    const [delay, setDelay] = useState<any>(500)
    const [result, setResult] = useState<any>('')


    const sound = new Howl({
        src: audio
    });


    const handleScan = async (data: any) => {
        if (data) {
            console.log("URL DATA", data);
            setResult(data)
            // const propertyDetailResponse = await getPropertyDetails("RC-UKMS-PT-1-000002")
            const propertyDetailResponse = await getPropertyDetails(data)
            console.log("PROPERT DETAILS", propertyDetailResponse?.data?.DDNNumber);
            if (propertyDetailResponse?.success) {
                // sound?.play();
                await updateUserInfo({ surveyKey: propertyDetailResponse?.data?._id, ownerName: propertyDetailResponse?.data?.propertyOwnerName, contactNumber: propertyDetailResponse?.data?.contactNumber, propertyCode: propertyDetailResponse?.data?.DDNNumber })
            }
            else {
                toast.error("Unable to fetch data ~")
            }
        }
    }

    useEffect(() => {
        if (result && userInfo?.surveyKey) {
            updateUserInfo({ propertyCode: result })
        }
    }, [userInfo?.surveyKey, result])

    useEffect(() => {
        if (userInfo?.surveyKey && userInfo?.propertyCode) {
            navigate("/user-availablity-status")
        }
    }, [userInfo?.surveyKey, userInfo?.propertyCode])


    const handleError = (err) => {
        console.error(err)
        toast.error(err)
    }

    const previewStyle = {
        height: 240,
        width: 320,
        video: {
            facingMode: "environment"
        }
    }
    useEffect(() => {

        handleScan(ddn)
    }, [ddn])

    return (
        <Box width={1} pt={6}>
            <BorderBox >
                <Box sx={{ minWidth: 240 }} display="flex" flexDirection="column" justifyContent="center">

                </Box>
            </BorderBox>
        </Box>
    )
}

export default ScanPropertyCode